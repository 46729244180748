<template>
  <div class="main">
    <!-- 搜索栏 -->
    <ListToolBar :disabled="disabledCreateBtn" @getList="getList" @onCreate="onCreate" @onDelete="onDelete" />
    <!-- 表格 -->
    <el-table class="tables" ref="table" v-loading="loading" :data="tableInfo.data" :header-cell-style="{
      background: '#F2F2F2',
      color: '#525252',
      fontSize: '13px',
      height: '30px'
    }" stripe border @selection-change="handleSelectionChange" style="height: calc(100% - 90px);" height="100%">
      <el-table-column type="selection" width="40" align="center" />
      <!-- 选择企业 -->
      <el-table-column :label="t('enterprise.name')" min-width="400px">
        <template #default="scope">
          <el-select v-if="scope.row.type === 'add'" v-model="scope.row.enterprise" filterable
            @change="onEnterpriseChange(scope.row)" :placeholder="t('enterprise.select_enterprise')" value-key="value"
            clearable>
            <el-option v-for="(enterprise, index) in enterpriseList" :key="index" :label="enterprise.label"
              :value="enterprise" />
          </el-select>
          <span v-else class="line-2">{{ scope.row.enterprise ? scope.row.enterprise.name : '-' }}</span>
        </template>
      </el-table-column>
      <!-- 选择产品 -->
      <el-table-column :label="t('product.product_name')" width="400px">
        <template #default="scope">
          <el-select v-if="scope.row.type === 'add'" :disabled="!!!scope.row.enterprise" v-model="scope.row.product_id"
            @change="onProductChange(scope.row, scope.$index)" :placeholder="t('enterprise.select_product')" filterable
            clearable>
            <el-option v-for="(product, index) in productList" :key="index" :label="product.label"
              :value="product.value" />
          </el-select>
          <span v-else class="line-2">{{ scope.row.product ? scope.row.product.name : '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" :label="t('table.action')" width="140px">
        <template #default="scope">
          <el-button v-if="scope.row.type" :disabled="saveBtn" :loading="loadingBtn" link type="primary"
            @click="saveData(scope.row)">
            {{ t('form.submit') }}
          </el-button>
          <el-button v-if="scope.row.type == 'add'" link type="primary" @click="deleteData(scope.$index)">
            {{ t('form.delete') }}
          </el-button>
          <el-popconfirm v-if="!scope.row.type" :title="t('form.sure_del')" @confirm="rowDel(scope.row)">
            <template #reference>
              <el-button link type="primary">
                {{ t('form.delete') }}
              </el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <Pager ref="page" :totalPage="tableInfo.totalPage" :pageSize="tableInfo.pageSize" @getList="pageChange" />
  </div>
</template>

<script setup>
import ListToolBar from './components/ListToolBar'
import { onMounted, reactive, ref, nextTick, getCurrentInstance, inject } from 'vue'
import { productManagement } from '@/utils/api'
import { getEnterpriseList, getSalesProduct } from '@/utils/common'
import { ElMessage } from 'element-plus'
import store from '@/store'

const table = ref()
const page = ref()
const disabledCreateBtn = ref(false)
const enterpriseList = ref([])// 企业列表
const productList = ref([])// 产品列表
const loading = ref(true)
const loadingBtn = ref(false) // 保存按钮loading
const saveBtn = ref(true)
const delList = ref([])//删除列表
const { proxy } = getCurrentInstance()
const searchData = ref({
  page: 1
})
const t = inject('t')
// 企业change时触发
const onEnterpriseChange = async (value) => {
  // 根据企业所属销售查询产品列表
  value.product_id = ''
  value.enterprise_id = value.enterprise.value
  if (!!!proxy.$userRole(['SalesMan'])) {
    productList.value = await getSalesProduct(value.enterprise.sales_user_id)
  } else {
    productList.value = await getSalesProduct(store.state.users.id)
  }
}

// 检测企业下是否存在该产品
const checkExist = (data) => {
  return new Promise((resolve, reject) => {
    productManagement.EnterpriseProduct({
      filter_product_id: data.product_id,
      filter_enterprise_id: data.enterprise_id
    }).then(res => {
      if (res.items.length) {
        ElMessage.warning(t('enterprise.exist_product'))
        resolve(0)
      }
      resolve(1)
    })
  })
}

// 产品change时触发
const onProductChange = async (data) => {
  if (!!!data.enterprise_id) {
    ElMessage.warning(t('enterprise.select_enterprise'))
    return
  }
  if (!!!data.product_id) {
    ElMessage.warning(t('enterprise.select_product'))
    return
  }
  const res = await checkExist(data)
  if (res) {
    saveBtn.value = false;
  } else {
    saveBtn.value = true;
  }
}

// 创建
const onCreate = async () => {
  disabledCreateBtn.value = true
  enterpriseList.value = await getEnterpriseList()
  tableInfo.data.unshift({
    enterprise_id: "", //企业id
    enterprise: {}, //企业
    type: 'add',
    product_id: ''
  })
}

// 保存
const saveData = async (data) => {
  if (!!!data.enterprise_id) {
    ElMessage.warning(t('enterprise.select_enterprise'))
    return
  }
  if (!!!data.product_id) {
    ElMessage.warning(t('enterprise.select_product'))
    return
  }
  const res = await checkExist(data)
  if (res) {
    productManagement.EnterpriseProductCreate(data).then(res => {
      ElMessage.success(t('table.add_success'))
      getList()
    })
  }
}

// 删除
const onDelete = async () => {
  if (!!!delList.value.length) {
    ElMessage.warning(t('table.select_del'))
    return
  } else {
    let arr = [];
    delList.value.forEach(item => {
      arr.push(deleteRequest(item))
    })
    await nextTick()
    Promise.all(arr).then(async () => {
      ElMessage.success(t('table.del_success'))
      getList()
      delList.value = []
    })
  }
}

// 多选事件
const handleSelectionChange = (data) => {
  delList.value = [];
  data.forEach(item => {
    delList.value.push(item.id)
  })
}

// 删除:不请求接口
const deleteData = () => {
  disabledCreateBtn.value = false
  tableInfo.data.splice(0, 1)
}

// 行内点击删除
const rowDel = async (data) => {
  const res = await deleteRequest(data.id)
  if (res) {
    ElMessage.success(t('table.del_success'))
    getList()
  }
}
// 删除:请求接口
const deleteRequest = (id) => {
  return new Promise((resolve, reject) => {
    productManagement.EnterpriseProductDelete(id).then(res => {
      resolve(1)
    })
  })
}

const tableInfo = reactive({
  data: [],
  totalPage: 0,
  pageSize: 0
})

// 分页触发改变
const pageChange = (current) => {
  searchData.value.page = current.page
  getList({ ...searchData.value })
}
// 获取表格数据
const getList = (search = null) => {
  loading.value = true
  saveBtn.value = true
  disabledCreateBtn.value = false
  searchData.value = search ? { ...search } : searchData.value
  page.value.resetCurrent(searchData.value.page)
  productManagement.EnterpriseProduct({
    ...searchData.value
  }).then(data => {
    tableInfo.data = data.items;
    tableInfo.totalPage = data.total
    tableInfo.pageSize = data.per_page
    loading.value = false
  }).catch(error => {
    loading.value = false
  })
}

onMounted(() => {
  getList()
})
</script>

<style scoped lang="scss">
.tables {
  margin-top: 10px;
}
</style>